<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px; min-height: 800px;">
      <b-row>

        <b-col lg="8" offset-lg="2">

          <h1 v-if="category.category_id" style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">{{ staticDisplayName }}</h1>
          <h1 v-else style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">New Category</h1>
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body>
              <b-form @submit.prevent="saveCategory" @reset.prevent="onReset">

              <!-- ACTIVE TOGGLE -->
              <toggle-button
                @change="toggleActive"
                :value="category.active"
                :sync="true"
                :color="{
                  checked: '#28a745',
                  unchecked: '#6c757d',
                  disabled: '#343a40'
                }"
                :labels="{ checked: 'Active', unchecked: 'Inactive' }"
                :width="85"
                :height="30"
                :font-size="12"
                class="float-right"
              />

              <!-- DISPLAY NAME -->
                <b-form-group
                  id="display-name-group"
                  label="Display Name:"
                  label-for="display-name"
                  description=""
                >
                  <p v-show="!validation.display_name" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a display name longer than 2 characters.
                  </p>
                  <b-form-input
                    id="display-name"
                    :class="{ 'error' : !validation.display_name}"
                    style="max-width: 350px;"
                    v-model="category.display_name"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>

              <!-- SLUG -->
                <b-form-group
                  id="slug-group"
                  label="Slug:"
                  label-for="slug"
                  description=""
                >
                  <p v-show="!validation.slug" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a category slug.
                  </p>
                  <b-form-input
                    id="slug"
                    :class="{ 'error' : !validation.slug }"
                    style="max-width: 350px;"
                    :disabled="category.category_id != null"
                    v-model="category.slug"
                    placeholder="example: digital_advertising"
                    trim
                  ></b-form-input>
                </b-form-group>

              <!-- DESCRIPTION SHORT -->
                <b-form-group
                  id="description-short-group"
                  label="Description Short:"
                  label-for="description-short"
                  description=""
                >
                  <p v-show="!validation.description_short" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a short category description longer than 10 characters.
                  </p>
                  <b-form-textarea
                    id="description-short"
                    :class="{ 'error' : !validation.description_short }"
                    v-model="category.description_short"
                    placeholder=""
                    trim
                  ></b-form-textarea>
                </b-form-group>

              <!-- DESCRIPTION #1 -->
                <b-form-group
                  id="description-1-group"
                  label="Description #1:"
                  label-for="description-1"
                  description=""
                >
                  <p v-show="!validation.description_1" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a category description longer than 10 characters.
                  </p>
                  <b-form-textarea
                    id="description-1"
                    :class="{ 'error' : !validation.description_1 }"
                    v-model="category.description_1"
                    placeholder=""
                    trim
                    rows="6"
                  ></b-form-textarea>
                </b-form-group>

              <!-- DESCRIPTION #2 -->
                <b-form-group
                  id="description-2-group"
                  label="Description #2:"
                  label-for="description-2"
                  description=""
                >
                  <p v-show="!validation.description_2" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a category description longer than 10 characters.
                  </p>
                  <b-form-textarea
                    id="description-2"
                    :class="{ 'error' : !validation.description_2 }"
                    v-model="category.description_2"
                    placeholder=""
                    trim
                    rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <!-- VENDOR CHECKBOXES FOR A BRAND NEW CATEGORY -->
                <b-form-group label="Certified Vendors:">
                  <b-form-checkbox-group class="pt-2" id="vendors" name="vendors" v-model="selected">
                    <b-form-checkbox v-for="vendor in allVendors" :key="vendor.vendor_id" :value="vendor.vendor_id" class="pl-4 pr-4 pb-3" style="width: 220px;">{{ vendor.display_name }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>

                <b-button v-show="!processing" type="submit" class="btn btn-primary btn-dash-submit float-right">Save</b-button>
                <b-button v-show="processing" disabled type="submit" class="btn btn-primary btn-dash-submit float-right"><b-spinner class="mr-2" small></b-spinner> Saving...</b-button>
                <b-button @click="cancel" class="btn btn-light float-right mr-3">Cancel</b-button>

              </b-form>

            </b-card-body>
          </b-card>

        </b-col>
      </b-row>      
    </b-col>
  </b-row>

</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  name: 'edit-category',
  components: {
    ToggleButton,
  },

  created () {
    if(this.$route.params.categoryId){
      this.getCategory();
    }
    this.getVendors();
  },

  data() {
    return {
      processing: false,
      category: {
        category_id: null,
        active: false,
        slug: '',
        display_name: '',
        description_short: '',
        description_1: '',
        description_2: '',
        vendors: '',
      },
      selected: [],
      allVendors: {},
      validation: {
        display_name: true,
        slug: true,
        description_short: true,
        description_1: true,
        description_2: true,
      }
    }
  },

  metaInfo() {
    return {
      title: this.category.display_name ? this.category.display_name : 'New Category'
    }
  },

  methods: {

    getCategory(){
      this.$store.commit('generateApiCreds');

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'categories/id/' + this.$route.params.categoryId, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.category = resp.data;
          this.staticDisplayName = this.category.display_name;
          this.category.active = this.category.active == 1 ? true : false;

          // make a seperate array for vendor checkboxes if they exist
          if(this.category.vendors){
            this.selected = resp.data.vendors.split(",");
          }
          
        })
        // .catch(err => {
        //   console.log(err);
        //   self.$router.push('/*');
        // });
    },

    saveCategory() {
      this.$store.commit("generateApiCreds");
      const self = this;

      if(!this.validateForm()){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return false;
      }

      this.processing = true;

      const formData = new FormData();

      // Is this an existing category? If so we need to set our id.
      if(this.category.category_id){
        formData.append('category_id', this.category.category_id);
      }

      formData.append('user_id', this.$store.state.auth.userId);
      formData.append('token', this.$store.state.auth.token);

      // Set everything else except vendors.
      for (let [key, value] of Object.entries(this.category)) {
        formData.append(key, value);
      }

      formData.append('vendors', this.selected.join(','));

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "categories", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.processing = false;
          self.$router.go(-1);
        })
        .catch(function() {
          // alert('We have an error on our site. Please contact support and let us know. Thanks!')
          // console.log(resp);
          // alert('error');
        });
    },

    validateForm() {
      this.validation.display_name        = this.category.display_name.length > 2 ? true : false;
      this.validation.slug                = this.category.slug.length > 2 ? true : false;
      this.validation.description_short   = this.category.description_short.length > 10 ? true : false;
      this.validation.description_1       = this.category.description_1.length > 10 ? true : false;

      // If any values in our validation object are false, then return false
      return Object.values(this.validation).every(Boolean);
    },

    getVendors(){
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'vendors', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.allVendors = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

    cancel() {
      this.$router.go(-1);
    },

    toggleActive() {
      this.category.active = !this.category.active;
    }

  },

  computed: {

  }

}
</script>

<style scoped>

.btn-dash-submit {
  background-color: #007cc0;
  border-color: #007cc0;
}

.btn-dash-submit:hover {
  background-color: rgb(23, 92, 142);
  border-color: rgb(23, 92, 142);
}

.error {
  border: 2px solid #d9534f;
}

.btn.disabled {
  cursor:not-allowed;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}

</style>
